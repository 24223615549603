html {
  height: '100%';
}

body {
  height: '100%';
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #002A3A;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #002A3A inset !important;
  -webkit-text-fill-color: #ffffff !important;
  background-color: #002A3A !important;
  background-clip: content-box !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.sound-wave{
  width: 100%;
  display: none !important;

}
.sound-wave-hidden{
  width: 100%;
  display: none !important;
}